import { $feedbackApi, SURVEY_SUBMISSION_BASE_PATH } from '@trustyou/shared';

import type {
  CreateSurveyFeedbackUseCaseIn,
  CreateSurveyFeedbackUseCaseOut,
  GetSurveyQuestionnaireApiSubmitGetQuestionnaireSurveyIdEntityIdGetResponse,
  Questionnaire,
} from '../client';

// import mockSurveyWithMultipleConditions from '../mock/survey-with-multiple-conditions.mock.json';
// import mockSurveyWithOneCondition from '../mock/survey-with-one-condition.mock.json';

type GetQuestionnaireParams = {
  surveyId: string;
  entityId: string;
  organizationId: Questionnaire['id'];
  outboundId: string;
  source?: string;
};

export async function fetchQuestionnaire({
  surveyId,
  entityId,
  organizationId,
  outboundId,
  source,
}: GetQuestionnaireParams) {
  const { data } =
    await $feedbackApi.get<GetSurveyQuestionnaireApiSubmitGetQuestionnaireSurveyIdEntityIdGetResponse>(
      `${SURVEY_SUBMISSION_BASE_PATH}/get-questionnaire/${surveyId}/${entityId}`,
      {
        params: {
          organization_id: organizationId,
          outbound_id: outboundId,
          ...(source && { source }),
        },
      }
    );
  return data;

  // return mockSurveyWithOneCondition as GetSurveyQuestionnaireApiSubmitGetQuestionnaireSurveyIdEntityIdGetResponse;
  // return mockSurveyWithMultipleConditions as GetSurveyQuestionnaireApiSubmitGetQuestionnaireSurveyIdEntityIdGetResponse;
}

type SubmitSurveyParams = {
  surveyId: string;
  entityId: CreateSurveyFeedbackUseCaseIn['entity_id'];
  organizationId: CreateSurveyFeedbackUseCaseIn['organization_id'];
  surveyFeedback: CreateSurveyFeedbackUseCaseIn['survey_feedback'];
  outboundId: CreateSurveyFeedbackUseCaseIn['outbound_id'];
  source: CreateSurveyFeedbackUseCaseIn['source'];
};

export async function submitSurvey({
  surveyId,
  entityId,
  organizationId,
  surveyFeedback,
  outboundId,
  source,
}: SubmitSurveyParams): Promise<CreateSurveyFeedbackUseCaseOut> {
  const { data } = await $feedbackApi.post<CreateSurveyFeedbackUseCaseOut>(
    `${SURVEY_SUBMISSION_BASE_PATH}/survey/${surveyId}`,
    {
      entity_id: entityId,
      organization_id: organizationId,
      survey_feedback: surveyFeedback,
      outbound_id: outboundId,
      ...(source && { source }),
    }
  );
  return data;
}

export async function getSurveyEmailUnsubscribeStatus(distributionId: string) {
  const { status } = await $feedbackApi.get<number>(
    `${SURVEY_SUBMISSION_BASE_PATH}/unsubscribe/${distributionId}`
  );
  return status;
}

export async function unsubscribeSurveyEmail(distributionId: string) {
  const { status } = await $feedbackApi.post<number>(
    `${SURVEY_SUBMISSION_BASE_PATH}/unsubscribe/${distributionId}`
  );
  return status;
}
