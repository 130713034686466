import { v4 as uuid } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { EXPIRY_DAYS } from '../utils';

type SurveyId = string;
type EntityId = string;
type Key = `${SurveyId}+${EntityId}`;
type Value = { outboundId: string; generatedAt: number };

type OutboundIdState = {
  outboundIdsObject: Record<Key, Value>;
  updateOutboundIdsObject: (key: Key) => void;
  checkExpiration: (key: Key) => void;
};

export const constructKey = (surveyId: string, entityId: string): Key => `${surveyId}+${entityId}`;

const generateValue = (): Value => ({
  outboundId: uuid(),
  generatedAt: new Date().getTime(),
});

const isExpired = (generatedAt: number) => {
  const now = new Date().getTime();
  const expiryTime = EXPIRY_DAYS * 24 * 60 * 60 * 1000;
  return now - generatedAt > expiryTime;
};

export const useOutboundIdStore = create<OutboundIdState>()(
  persist(
    (set, get) => ({
      outboundIdsObject: {},
      updateOutboundIdsObject: (key) => {
        set((state) => ({
          outboundIdsObject: { ...state.outboundIdsObject, [key]: generateValue() },
        }));
        window.location.reload();
      },
      checkExpiration: (key) => {
        const { outboundIdsObject: outboundIds } = get();
        if (outboundIds?.[key]) {
          const { generatedAt } = outboundIds[key];
          if (isExpired(generatedAt)) {
            set((state) => ({
              outboundIdsObject: { ...state.outboundIdsObject, [key]: generateValue() },
            }));
            window.location.reload();
          }
        }
      },
    }),
    {
      name: 'outbound-ids',
    }
  )
);
