import { FormattedMessage, IntlProvider } from 'react-intl';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  errorMessages,
  ignoreMissingTranslationWarnings,
  useLanguageStore,
} from '@trustyou/shared';
import { messages as surveyMessages } from '@trustyou/survey-manager';
import { SnackbarProvider, snackbar } from '@trustyou/ui';

import { routes } from './routes';

import { useThemeStore } from './store';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      console.error(error);
      snackbar.error(<FormattedMessage {...errorMessages.genericError} />);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      console.error(error);
      snackbar.error(<FormattedMessage {...errorMessages.genericError} />);
    },
  }),
});

const router = createBrowserRouter(routes, {
  basename: `/${process.env.REACT_APP_ROUTER_BASENAME}`,
});

export default function App() {
  const { locale } = useLanguageStore();
  const surveyTheme = useThemeStore((state) => state.theme);

  return (
    <IntlProvider
      onError={ignoreMissingTranslationWarnings}
      locale={locale}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      messages={surveyMessages[locale as keyof typeof surveyMessages]}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={surveyTheme}>
          <SnackbarProvider>
            <CssBaseline />
            <RouterProvider router={router} />
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}
