import { Fragment, useState } from 'react';
import { type SubmitErrorHandler, type SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { type LoaderFunction, redirect, useLoaderData, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { useLanguageStore } from '@trustyou/shared';
import {
  MAX_CARD_WIDTH_PX,
  SurveyFooter,
  SurveyHeader,
  SurveyLanguageSelector,
  SurveyMessageContent,
  SurveyPagination,
  SurveySubmitButton,
  useFeedbackPaginationStore,
} from '@trustyou/survey-manager';
import { Box, Stack, Typography, snackbar } from '@trustyou/ui';

import type {
  CreateSurveyFeedbackUseCaseIn,
  FeedbackSourceEnum,
  GetSurveyQuestionnaireUseCaseOut,
  Image,
  Language,
  ThankyouMessage,
} from './client';
import { useSurvey } from './hooks/use-survey';
import type { Content } from './types';
import {
  NOT_APPLICABLE,
  findLocale,
  getLanguageOptions,
  isImage,
  isQuestion,
  isSection,
  isText,
  useValidationSchema,
} from './utils';

import { fetchQuestionnaire, submitSurvey } from './service/api';
import { useConditionalQuestions } from './service/hooks/use-conditional-questions';
import { useSurveyTheme } from './service/hooks/use-survey-theme';
import { constructKey, useOutboundIdStore, useThemeStore } from './store';

export type FormValue = string | number | boolean | null;

export type FormValues = {
  [questionId: string]: FormValue | FormValue[];
};

export type SurveyData = {
  data: GetSurveyQuestionnaireUseCaseOut;
  outboundId: CreateSurveyFeedbackUseCaseIn['outbound_id'];
  source: CreateSurveyFeedbackUseCaseIn['source'];
};

const loader: LoaderFunction = async ({ request, params }) => {
  const { organizationId = '', entityId = '', surveyId = '' } = params;
  const { outboundIdsObject, updateOutboundIdsObject, checkExpiration } =
    useOutboundIdStore.getState();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const source = urlSearchParams.get('source') as FeedbackSourceEnum;
  const outboundIdFromURL = urlSearchParams.get('outbound_id');

  let generatedOutboundId = '';
  if (!outboundIdFromURL) {
    const key = constructKey(surveyId, entityId);
    checkExpiration(key);
    if (!(key in outboundIdsObject)) {
      updateOutboundIdsObject(key);
    }
    generatedOutboundId = outboundIdsObject?.[key]?.outboundId;
  }
  const outboundId = outboundIdFromURL ?? generatedOutboundId;

  const data = await fetchQuestionnaire({
    organizationId,
    entityId,
    surveyId,
    outboundId,
    source,
  });

  if ('redirect_to' in data) {
    return redirect(data.redirect_to);
  }

  return { data, outboundId, source };
};

export default function Survey() {
  const theme = useThemeStore((state) => state.theme);
  const [isThankYouPage, setIsThankYouPage] = useState(false);
  const { organizationId = '', entityId = '', surveyId = '' } = useParams();
  const { data, outboundId, source } = useLoaderData() as SurveyData;
  const questionnaire = data?.questionnaire;
  const entity = data?.entity;
  const availableLanguages = data?.available_languages;

  // TODO: Fix isSupported boolean check within useLanguageStore.
  const { updateLocale } = useLanguageStore();
  const [language, setLanguage] = useState(questionnaire?.default_language);

  const handleChangeLanguage = (shortLanguageCode: Language) => {
    setLanguage(shortLanguageCode);
    const newLocale = findLocale(shortLanguageCode);
    updateLocale(newLocale as string);
  };

  const { currentPage } = useFeedbackPaginationStore();
  const pageCount = questionnaire.content?.length ?? 1;
  const hasPages = pageCount > 1;
  const isLastPage = currentPage + 1 === pageCount;

  const pageContent = questionnaire.content.find((item) => item.page === currentPage);
  const submitSectionPageContent = questionnaire.submit_section?.content ?? [];

  const contentIds =
    pageContent?.content?.flatMap((item) => {
      if (isSection(item)) {
        const section = item;
        const sectionTitleId = section.id;
        const sectionItemIds = section.content?.map((sectionItem) => sectionItem.id) ?? [];
        return [sectionTitleId, ...sectionItemIds];
      }
      return item.id;
    }) ?? [];
  const submitSectionIds = submitSectionPageContent.flatMap((item) => item.id);

  const headerImage = questionnaire.content_items[
    questionnaire.theme?.header_image?.id ?? ''
  ] as Image;

  const contentItems = contentIds.map((id) => questionnaire.content_items[id]);
  const submitSectionContentItems = submitSectionIds.map((id) => questionnaire.content_items[id]);

  const questions = contentItems.filter(isQuestion);
  const submitSectionQuestions =
    currentPage + 1 === pageCount ? submitSectionContentItems.filter(isQuestion) : [];

  const validationSchema = useValidationSchema([...questions, ...submitSectionQuestions]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    trigger,
    setValue,
    resetField,
  } = useForm<FormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  const { shouldRender } = useConditionalQuestions({ contentItems, watch, resetField });

  const onValid: SubmitHandler<FormValues> = async (data) => {
    const surveyFeedback = Object.entries(data).map(([questionId, value]) => ({
      question_id: questionId,
      value: value ? (value === NOT_APPLICABLE ? null : value) : null,
      ...(value === NOT_APPLICABLE && { not_applicable: true }),
    }));

    try {
      await submitSurvey({
        organizationId,
        entityId,
        surveyId,
        surveyFeedback,
        outboundId,
        source,
      });
      setIsThankYouPage(true);
    } catch (error) {
      console.error('Failed to submit data.', error);
      snackbar.error('Failed to submit data.');
    }
  };

  const onInvalid: SubmitErrorHandler<FormValues> = (errors, event) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    console.warn({ errors, event });
    snackbar.error('Invalid form. Check the questions with warnings.');
  };

  const {
    renderWelcome,
    renderSectionTitle,
    renderText,
    renderImage,
    renderQuestion,
    renderSubmitSection,
  } = useSurvey({ questionnaire, contentIds, language, control, errors });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useSurveyTheme(questionnaire.theme!);

  const renderContentItem = (item: Content) => {
    if (isSection(item)) return renderSectionTitle(item);
    if (isQuestion(item)) return renderQuestion({ question: item, setValue });
    if (isText(item)) return renderText(item);
    if (isImage(item)) return renderImage(item);
    console.warn(
      `Unhandled content type ${(item as Content).type} with id ${(item as Content).id}`
    );
    return null;
  };

  return (
    <Stack
      sx={{
        maxWidth: MAX_CARD_WIDTH_PX,
        minHeight: isThankYouPage ? 'auto' : '100vh',
        margin: 'auto',
        paddingBlockStart: { xs: 2, sm: 3 },
        gap: { xs: 2, sm: 3 },
      }}
    >
      <Stack sx={{ alignSelf: 'end', paddingInline: { xs: 2, sm: 0 } }}>
        <SurveyLanguageSelector
          languageOptions={getLanguageOptions(availableLanguages)}
          defaultLanguage={language}
          onChange={handleChangeLanguage}
        />
      </Stack>
      {(headerImage || entity || pageCount > 1) && (
        <SurveyHeader
          image={
            headerImage
              ? {
                  url: headerImage.url as string,
                  variant: headerImage.metadata?.classic_info?.type as 'banner' | 'logo',
                }
              : undefined
          }
          entity={
            entity.name
              ? { name: entity.name, color: questionnaire.theme?.colors.entity_name }
              : undefined
          }
          pagination={isThankYouPage ? undefined : { pageCount }}
          trigger={trigger}
        />
      )}
      <Stack sx={{ flexGrow: 1 }}>
        {isThankYouPage && questionnaire.thankyou_message ? (
          <SurveyMessageContent
            title={
              (questionnaire.content_items[questionnaire.thankyou_message.id] as ThankyouMessage)
                .title?.[language]
            }
            description={
              (questionnaire.content_items[questionnaire.thankyou_message.id] as ThankyouMessage)
                .description?.[language]
            }
          />
        ) : (
          <Stack
            component="form"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(onValid, onInvalid)}
            sx={{ flexGrow: 1, gap: { xs: 2, sm: 3 } }}
          >
            {renderWelcome(currentPage)}
            {contentItems.filter(shouldRender).map((item) => (
              <Fragment key={item.id}>{renderContentItem(item)}</Fragment>
            ))}
            {currentPage + 1 === pageCount &&
              questionnaire.submit_section &&
              renderSubmitSection(
                <Stack sx={{ marginBlock: 2 }}>
                  {currentPage + 1 === pageCount && <SurveySubmitButton loading={isSubmitting} />}
                </Stack>
              )}
            <Stack sx={{ paddingInline: { xs: 2, sm: 0 }, gap: { xs: 2, sm: 3 } }}>
              {hasPages && !isLastPage && (
                <SurveyPagination pageCount={pageCount} trigger={trigger} />
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
      {!isThankYouPage && <Box height={32} />}
      {!isThankYouPage && (
        <Typography
          variant="body2"
          sx={{
            color: (theme) =>
              theme.palette.getContrastText(
                questionnaire.theme?.colors.background ?? theme.palette.background.default
              ),
          }}
        >
          <FormattedMessage
            id="survey.mandatory-questions.disclaimer"
            defaultMessage="Fields marked with * are mandatory."
          />
        </Typography>
      )}
      <SurveyFooter entity={entity} theme={theme} />
    </Stack>
  );
}

Survey.loader = loader;
